import React from "react";
// import Img1 from "assets/images/Rectangle-10.png";
// import Img2 from "assets/images/Rectangle-10-1.png";
// import Img3 from "assets/images/Rectangle-10-2.png";
// import Img4 from "assets/images/Rectangle-10-3.png";
import Img5 from "assets/images/Group-71.png";
import Img6 from "assets/images/Health-and-Safety-Management (1).png";
import Img7 from "assets/images/Group-69-1.png";
import Group137 from "assets/images/Group-137.png";
import Group138 from "assets/images/Group-138.png";
import Group139 from "assets/images/Group-139.png";
import Group140 from "assets/images/Group-140.png";
import Group141 from "assets/images/Group-141.png";
import GreatTrans from "assets/images/Great-Trans (1).png";
import IRIFELIX from "assets/images/IRIFELIX-logo.png";
import marung from "assets/images/marung-logo.png";
import { Text } from "components/Typography/Typography";
import { ConsultancyData } from "data/consultancyData";
import Header from "components/Header/header";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import EneroLogo from "assets/images/enero-logo.png";
import Miletranslogo from "assets/images/mile-trans-logo.png";
import Pavellogo from "assets/images/pavel-logo.png";
import Rsspedition from "assets/images/r&s spedition.png";
import tmspeedy from "assets/images/tm-speedy.png";


const consultancy = () => {

  return (
    <div>
      <Helmet>
        <title>Consultancy</title>
      </Helmet>
      <Header />
      <section className="Transport-Management-section" style={{ padding: "0px 0px 30px 0px" }}>
        <div className="container" >
          <div className="row">
            <div className="col-bg-12 text-lg-start Trainings-We-Offer">
              <Text
                className={"lh-1"}
                label={
                  ConsultancyData?.title?.split(" ")?.[0] +
                  " " +
                  ConsultancyData?.title?.split(" ")?.[1]
                }
                fontSize={"36px"}
                lineHeight={1.3}
                color={
                  ConsultancyData?.title?.split(" ")?.[0]
                    ? "#3A47BC"
                    : "#67D3D1"
                }
              />
              <Text label={ConsultancyData?.content} />
            </div>
          </div>
          <div className="row" style={{ padding: "10px" }}>
            {ConsultancyData?.TransportDetail?.map((item) => (
              <div className="col-md-3 Transport-Management-col1">
                <h4>{item?.title}</h4>
                <div className="w-100 Transport-Management-col">
                  <img
                    src={item?.image}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <p>{item?.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* ////////////////////Mobile////////////////////////// */}

      <section className="What-We-Offer-Stsection " style={{ padding: "50px 0px" }}>
        <div className="container">
          <div className="row">
            <div
              className="ase"
              style={{ display: "flex", paddingTop: "50px, 0px" }}
            >
              <div className="col-sm-6">
                <img src={Img5} style={{ width: "500px" }} />
              </div>
              <div
                className="col-sm-6"
                style={{ padding: "100px 90px 0px 90px" }}
              >
                <h1 className="fade-right1 text-left">
                  <span style={{ color: "#3A47BC" }}>What We </span>
                  <span style={{ color: "#67D3D1" }}>Offer</span>
                </h1>
                <p>
                  MS Blue main objective is to raise standards across the
                  industry, we serve H&S legal compliance, Safety Audits,
                  Training and Quality Systems.
                </p>
                <Link
                  to={"/contact-us"}
                  className="btn"
                  role="button"
                  style={{
                    backgroundColor: "#3a47bc",
                    color: "#fff",
                    borderRadius: "30px",
                  }}
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="Your-Futures-Partner-Mob "
        style={{ backgroundColor: "#f5f5f5", padding: "0px 0px 30px 0px" }}
      >
        <div className="container">
          <div className="row">
            <div className="ase">
              <div
                className="col-sm-6"
                style={{ padding: "30px 0px 30px 0px" }}
              >
                <h1 className="fade-right1 text-left">
                  <span style={{ color: "#3A47BC" }}>What We </span>
                  <br />
                  <span style={{ color: "#67D3D1" }}>Offer</span>
                </h1>
                <p>
                  MS Blue main objective is to raise standards across the
                  industry, we serve H&S legal compliance, Safety Audits,
                  Training and Quality Systems.
                </p>
                <a
                  href="#link"
                  className="btn"
                  role="button"
                  style={{
                    backgroundColor: "#3a47bc",
                    color: "#fff",
                    borderRadius: "30px",
                  }}
                >
                  Contact Us
                </a>
              </div>
              <div className="col-sm-6">
                <img src={Img5} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        className="Health-and-Safety"
        style={{ height: "100%", paddingTop: "110px", paddingBottom: "125px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-md-1"></div>
            <div className="col-sm-3 col-md-10 text-lg-start text-center">
              <h1 className="fade-right1 text-center">
                <span style={{ color: "#3A47BC" }}>Health and Safety </span>
                <span style={{ color: "#67D3D1" }}>Management</span>
              </h1>
              <div className="Management text-center">
                <img src={Img6} style={{ height: "530px" }} />
              </div>
            </div>
            <div className="col-sm-3 col-md-1"></div>
          </div>
        </div>
      </section> */}
      {/* //////////////////Mobile//////////////////////// */}
      <section className="Health-and-Safety">
        <div className="container">
          <div className="row">
            <div className="col-bg-12 text-lg-start">
              <h1 className="fade-right1 text-center">
                <span style={{ color: "#3A47BC" }}>Health and Safety</span>
                <br />
                <span style={{ color: "#67D3D1" }}>Management</span>
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="col-md-4-div">
                <div style={{ width: "120px", height: "auto", margin: "auto" }}>
                  <img src={Group137} />
                </div>

                <h3>Consultancy</h3>
                <p>
                  We will act as your Competent or Appointed Person for all your
                  organizational Health and Safety.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="col-md-4-div">
                <div style={{ width: "120px", height: "auto", margin: "auto" }}>
                  <img src={Group138} />
                </div>
                <h3>COSHH</h3>
                <p>
                  We also specialises in COSHH health and safety, handling and
                  awareness. From COSHH RAs to COSHH registers.
                </p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="col-md-4-div">
                <div style={{ width: "120px", height: "auto", margin: "auto" }}>
                  <img src={Group139} />
                </div>
                <h3>Training</h3>
                <p>
                  Training We are EUSR (Energy and Utilities Safety Register)
                  approved trainers in CSCS
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center">
              <div className="col-md-4-div">
                <div style={{ width: "120px", height: "auto", margin: "auto" }}>
                  <img src={Group140} />
                </div>
                <h3>Safety Audits</h3>
                <p>
                  We, at MS Blue are here to help. Whether you require an audit to
                  satisfy a client, an auditing body or you simply want to put
                  your mind at ease.
                </p>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="col-md-4-div">
                <div style={{ width: "120px", height: "auto", margin: "auto" }}>
                  <img src={Group141} />
                </div>
                <h3>Quality Systems</h3>
                <p>
                Our expertise in ISO standards 9001 and 17025 helps optimize your processes and certify your products and services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////// */}
      <section className="Your-Futures-Partner" style={{ padding: "43px 0px" }}>
        <div className="container">
          <div className="row">
            <div
              className="ase"
              style={{ display: "flex" }}
            >
              <div
                className="col-sm-6"
                style={{ padding: "50px 90px 0px 0px" }}
              >
                <h1 className="fade-right1 text-left">
                  <span style={{ color: "#3A47BC" }}>Your Future’s</span>
                  <br />
                  <span style={{ color: "#67D3D1" }}>Partner</span>
                </h1>
                <p>
                  With a combined experience of over 45 years in QHSE and
                  Transport management at the highest levels and responsible for
                  millions of pounds in budgets, our team have decided to start
                  MS Blue.
                </p>
                <p>
                  Our main objective is to raise standards across the industries
                  we serve through proactive normative regulation, advice and
                  auditing. We offer bespoke packages for all aspects of QHSE
                  and Transport management or turn-key solutions designed to
                  comply with the latest ISO standards.Contact Us
                </p>
                <a
                  href="/contact-us"
                  className="btn"
                  role="button"
                  style={{
                    backgroundColor: "#3a47bc",
                    color: "#fff",
                    borderRadius: "30px",
                  }}
                >
                  Contact Us
                </a>
              </div>
              <div className="col-sm-6">
                <img src={Img7} style={{ width: "500px" }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ///////////////////Mobile/////////////////////// */}
      <section
        className="Your-Futures-Partner-Mob "
        style={{ backgroundColor: "#f5f5f5", padding: "0px 0px 30px 0px" }}
      >
        <div className="container">
          <div className="row">
            <div className="ase">
              <div
                className="col-sm-6"
                style={{ padding: "30px 0px 30px 0px" }}
              >
                <h1 className="fade-right1 text-left">
                  <span style={{ color: "#3A47BC" }}>Your Future’s</span>
                  <br />
                  <span style={{ color: "#67D3D1" }}>Partner</span>
                </h1>
                <p>
                  With a combined experience of over 45 years in QHSE and
                  Transport management at the highest levels and responsible for
                  millions of pounds in budgets, our team have decided to start
                  MS Blue.
                </p>
                <p>
                  Our main objective is to raise standards across the industries
                  we serve through proactive normative regulation, advice and
                  auditing. We offer bespoke packages for all aspects of QHSE
                  and Transport management or turn-key solutions designed to
                  comply with the latest ISO standards.Contact Us
                </p>
                <a
                  href="#link"
                  className="btn"
                  role="button"
                  style={{
                    backgroundColor: "#3a47bc",
                    color: "#fff",
                    borderRadius: "30px",
                  }}
                >
                  Contact Us
                </a>
              </div>
              <div className="col-sm-6">
                <img src={Img7} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container' style={{ padding: "30px 20px 30px 20px" }}>
          <div className='row d-grid logos-row'>
            <div className="col text-center" style={{ padding: "5px" }}>
              <div style={{ width: "130px", margin: "auto" }}>
                <img src={Rsspedition} />
              </div>
            </div>
            <div className="col text-center" style={{ padding: "5px" }}>
              <div style={{ width: "130px", margin: "auto" }}>
                <img src={marung} />
              </div>
            </div>
            <div className="col  text-center" style={{ padding: "5px" }}>
              <div style={{ width: "130px", margin: "auto" }}>
                <img src={IRIFELIX} />
              </div>
            </div>
            <div className="col  text-center" style={{ padding: "5px" }}>
              <div style={{ width: "130px", margin: "auto" }}>
                <img src={GreatTrans} />
              </div>
            </div>
            <div className="col  text-center" style={{ padding: "5px" }}>
              <div style={{ width: "130px", margin: "auto" }}>
                <img src={EneroLogo} />
              </div>
            </div>
            <div className="col  text-center" style={{ padding: "5px" }}>
              <div style={{ width: "130px", margin: "auto" }}>
                <img src={Miletranslogo} />
              </div>
            </div>
            <div className="col  text-center" style={{ padding: "5px" }}>
              <div style={{ width: "130px", margin: "auto" }}>
                <img src={Pavellogo} />
              </div>
            </div>
            <div className="col  text-center" style={{ padding: "5px" }}>
              <div style={{ width: "130px", margin: "auto" }}>
                <img src={tmspeedy} />
              </div>
            </div>

          </div>
        </div>

      </section>
    </div>
  );
};

export default consultancy;
