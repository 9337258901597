export const Form_Fields = {
  first_name: "",
  middle_name: "",
  last_name: "",
  company_name: "",
  email: "",
  phone_number: "",
};

export const CoursesName = [
  "Operator License Awareness Training",
  "Transport Manager CPC Refresher Training",
  "Periodic Driver CPC Training",
  "IRTEC Accredited HGV Inspection Technician Training",
  "CILT accredited Transport Manager CPC",
  "Highfield Level 3 Award in Delivering Training (RQF)",
  "Highfield Level 3 Award in Health and Safety in the Workplace",
];

export const CourseDates = [

  
  // "03 December 2024",
  "04 February 2025 ",
  "08 April 2025 ",
  "03 June 2025 ",
  "05 August 2025",
  "07 October 2025 ",
  "02 December 2025 ",
];