import React from "react";
import { HomeData } from "data/homeData";
import { Text } from "components/Typography/Typography";
import { useMobile } from "utils/CustomHooks/useDeviceResponsive";
import Skeleton from "components/Skeleton/Skeleton";
import Header from "components/Header/header";
import { Helmet } from "react-helmet";


const Home = () => {
  const isMobile = useMobile();
  return (
    <>
    {/* <!-- Google Tag Manager (noscript) --> */}
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-56RQ53WT"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
{/* <!-- End Google Tag Manager (noscript) --> */}
     <Helmet>
        <title>MS Blue</title>
      </Helmet>
      <Header />

      <section className="Different-Stsection">

        <div className="container">
          <div className="row">
            <div className={`${isMobile && 'my-5'}`}>
            <Text
              className={"text-center"}
              label={"Why Are We "}
              fontSize={"42px"}
              color={"#3a47bc"}
              lineHeight={"52px"}
              fontWeight={"701"}
              margin={0}
            />
            <Text 
              className={"text-center py-1"}
              label={"Different From Others?"}
              fontSize={"42px"}
              fontWeight={"701"}
              color={"#67d3d1"}
              lineHeight={1.2}
            />
            </div>
            <div className="row my-5">
              <img
                src={isMobile ? HomeData?.mobileImage : HomeData?.contentImage}
                style={{ width: "500px" }}
              />
            </div>
            <div className="ase my-5">
              <div className="col-sm-6 col-About">
                <h1 className="fade-right1 text-left">
                  <span style={{ color: "#3A47BC" }}>{HomeData?.heading}</span>
                </h1>
                <p style={{  margin:"20px 0px", fontFamily:"'Roboto'", fontWeight:"700" }}>{HomeData?.content}</p>
                <a
                  href="/contact-us"
                  className="btn mb-5"
                  role="button"
                  style={{
                    backgroundColor: "#3a47bc",
                    color: "#fff",
                    borderRadius: "30px",
                  }}
                >
                  {HomeData?.buttonText}
                </a>
              </div>
              <div className="col-sm-6 text-center">
                <img src={HomeData?.image} style={{ width: "500px" }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Trainings-We-Offer-Stsection">
        <div className="container">
        <div className="row"> 
              <div
                className="col-bg-12 text-lg-start Trainings-We-Offer"
              
              >
                <h1 className="fade-right1 text-center">
                  <span style={{ color: "#3A47BC" }}>Training, </span>
                  <span style={{ color: "#67D3D1" }}>We Offer</span>
                </h1>
                <p>
                  Flexible training options which suit operational needs and
                  individual learning
                  <br />
                  styles to run a compliant and efficient transport operation.
                </p>
              </div>
            </div>
          <div className="row">
            <div
              className="ase"
            
            >
              {HomeData?.CourseDetail?.map((item, index) => (
                <div key={index} className="col-sm-3 Trainings-We-Offer-col">
                  <div
                    style={{ height: "auto", width: "75px", margin: "auto" }}
                  >
                    <img src={item.image} />
                  </div>
                  <h5>{item?.heading}</h5>
                  <p>{item?.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
