
import React, { useState } from "react";
import "pages/Home/index.css";
import Header from "components/Header/header";
import { Helmet } from "react-helmet";
import weblogo from "assets/images/MS-BLUE-LTD-logo-1.png";

const ComplaintsPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Complaints Policy</title>
            </Helmet>
            <Header />

            <div className="container">
                <div className="row">
                </div>
                <div className="col-md-12 mb-contact-resp">
                    {/* <div className="px-5"> */}
                    {/* <h2 style={{ color: "#3a47bc" }} className="mb-text">
                        Contact
                        </h2> */}
                    <h1 style={{ color: "#88d5d3", fontSize: "38px" }}>Complaints Policy</h1>
                    {/* <div className="AppealsPolicy-table">
                        <table>
                            <tr>
                               
                                <td class="title-cell" colspan="2">COMPLAINTS POLICY</td>
                            </tr>
                            <tr>
                                <td>Document</td>
                                <td>MSBCD/QP/001</td>
                            </tr>
                            <tr>
                                <td>Version</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>Created by</td>
                                <td>Ali Waqar</td>
                            </tr>
                            <tr>
                                <td>Authorised</td>
                                <td>Monika Swatek</td>
                            </tr>
                            <tr>
                                <td>Issue date</td>
                                <td>22/07/2023</td>
                            </tr>
                            <tr>
                                <td>Reviewed</td>
                                <td>01/03/2024</td>
                            </tr>
                            <tr>
                                <td>Due date</td>
                                <td>01/03/2026</td>
                            </tr>
                        </table>

                    </div> */}

                    <div style={{ paddingTop: "20px" }}>
                        <h4>1. Purpose and Scope</h4>
                        <p>MS Blue Ltd is committed to providing high-quality training services and maintaining
                            a positive and professional relationship with all our clients and stakeholders. We
                            recognize the importance of addressing any concerns or complaints raised by
                            individuals who interact with our organization.</p>
                        <p>The purpose of this Complaints Policy is to establish a structured framework for the
                            fair and efficient handling of complaints and to ensure that all complaints are dealt
                            with promptly and appropriately</p>
                        <p>This policy applies to all clients, employees, contractors, and stakeholders
                            associated with MS Blue Ltd.</p>
                        <h4>2. Definition of a Complaint</h4>
                        <p>A complaint is defined as any expression of dissatisfaction, whether written or verbal,
                            related to MS Blue Ltd's training services, staff conduct, facilities, processes, or any
                            other aspect of our operations that falls within our control
                        </p>
                        <h4>3. Complaints Handling Process
                        </h4>
                        <h5>3.1 Receipt of Complaints
                        </h5>
                        <p>Complaints can be submitted through various channels, including in person, by
                            phone, via email, or through our official website.</p>
                        <p>All complaints received will be treated confidentially and impartially.
                        </p>
                        <h5>3.2 Acknowledgment and Record-Keeping</h5>
                        <p>Upon receipt of a complaint, MS Blue Ltd will acknowledge the complaint in writing or
                            through other appropriate means within 7 business days.</p>
                        <p>Complaints will be recorded in a centralized complaint register, which will include
                            relevant details such as the complainant's name, contact information, nature of the
                            complaint, and actions taken to resolve the issue.</p>
                        <h5>3.3 Investigation and Resolution</h5>
                        <p>The Complaints Handling Officer (CHO) or designated personnel will conduct a
                            thorough and impartial investigation of the complaint.</p>
                        <p>The CHO may seek additional information from the complainant or other relevant
                            parties to fully understand the circumstances surrounding the complaint.</p>
                        <p>MS Blue Ltd will make reasonable efforts to resolve the complaint promptly and
                            within 14 business days. However, more complex complaints may require a longer
                            resolution period.</p>
                        <h5>3.4 Communication and Updates</h5>
                        <p>Throughout the complaints handling process, MS Blue Ltd will keep the complainant
                            informed of the progress and expected timeframe for resolution.
                        </p>
                        <p>If additional time is needed to investigate the complaint fully, the complainant will be
                            notified, and the reasons for the delay will be communicated.</p>
                        <h5>3.5 Resolution and Follow-up</h5>
                        <p>Once the investigation is complete, MS Blue Ltd will communicate the resolution to
                            the complainant in writing or through other appropriate means.</p>
                        <p>If the complaint is found to be valid, appropriate corrective actions will be taken to
                            prevent similar issues from recurring.</p>
                        <p>The resolution will also include information about the complainant's right to escalate
                            the matter if they are not satisfied with the outcome.</p>
                        <h5>3.6 Escalation Procedure
                        </h5>
                        <p>If the complainant is not satisfied with the resolution provided by MS Blue Ltd, they
                            can escalate their complaint to the CILT(UK) Awarding Organisation, or generic,
                            Awarding Body</p>
                        <p>Details on how to escalate the complaint will be provided to the complainant during
                            the resolution communication.
                        </p>
                        <h4>4. Review and Continuous Improvement</h4>
                        <p>MS Blue Ltd is committed to reviewing the effectiveness of the Complaints Policy
                            regularly. Feedback from complaints will be used to identify areas for improvement
                            and to enhance the quality of our training services and overall operations.</p>
                        <h4>5. Communication
                        </h4>
                        <p>This Complaints Policy will be made available to all clients and stakeholders through
                            our official website, intranet, and training materials.</p>
                        <p>By implementing this Complaints Policy, MS Blue Ltd aims to foster a culture of
                            openness, responsiveness, and continuous improvement while maintaining the
                            highest level of customer satisfaction.</p>
                        {/* <p><strong>MONIKA SWATEK</strong></p> */}
                        <p><strong>General Manager</strong></p>
                        <p><strong>MS BLUE LTD</strong></p>
                    </div>

                </div>
                {/* </div> */}
            </div>


        </>
    );
};

export default ComplaintsPolicy;
