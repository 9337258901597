import React, { useEffect, useMemo, useRef, useState } from "react";
import InputWithTitle from "components/Inputs/InputsWithTitle";
import { Checkboxes, FeedBack, formData } from "data/feedbackdata";
import RadioButton from "components/Inputs/RadioButton";
import { Text } from "components/Typography/Typography";
import Header from "components/Header/header.jsx";
import { SimpleButton } from "components/Buttons/SimpleButton";
import "pages/FeedBackForm/index.css";

const FeedbackForm = () => {
  const [formHandler, setFormHandler] = useState({
    formValues: "" || {},
    currentItemToShow: "",
    currentIndexToShow: null,
    isErrorShow: [],
    isErrorEnable: false,
  });
  const [checkBoxValue, setCheckBoxValue] = useState([]);
  const ScrollRef = useRef([]);

  useEffect(() => {
    ScrollRef?.current?.forEach((item) => {
      console.log(item)
    })

  }, [formHandler?.isErrorEnable]);

  const OnChangeValue = (val, key, isError) => {
    setFormHandler((prevValues) => ({
      ...prevValues,
      formValues: {
        ...prevValues?.formValues,
        [key]: val,
      },
    }));
  };

  const OnFindIndexofArray = (array, parentIndex, index) => {
    return array?.findIndex((obj) => obj[index] === parentIndex);
  };

  const OnCheckBoxValue = (item, index, parentIndex, type) => {
    setFormHandler({
      ...formHandler,
      currentItemToShow: item,
      currentIndexToShow: parentIndex,
    });
    setCheckBoxValue((prevValues) => {
      const existingIndex = OnFindIndexofArray(
        prevValues,
        parentIndex,
        "index"
      );
      if (existingIndex !== -1) {
        const updatedValues = [...prevValues];
        updatedValues[existingIndex].key = item;
        updatedValues[existingIndex].currentIndex = index;
        updatedValues[existingIndex].type = type;
        return updatedValues;
      } else {
        return [
          ...prevValues,
          {
            key: item,
            index: parentIndex,
            currentIndex: index,
            type: type,
          },
        ];
      }
    });
  };

  const onSubmitbutton = () => {
    const Check_Empty_Values = Object.keys(formData.generalFeedback).map(
      (item) => {
        return {
          key: item,
          error: !formHandler.formValues[item],
          message: "Please Enter the Empty Field",
        };
      }
    );
    setFormHandler((prevValues) => ({
      ...prevValues,
      isErrorShow: Check_Empty_Values,
      isErrorEnable: true,
    }));
  };
  
  return (
    <div>
      <Header />
      <div className="container">
        {Object.keys(formData?.generalFeedback).map((key, index) => (
          <InputWithTitle
            ref={(element) => (ScrollRef.current[key] = element)}
            key={index}
            type={formData?.generalFeedback?.[key]?.type}
            width={"100%"}
            className={"training-main-input"}
            inputClass={"training-input"}
            label={`${formData?.generalFeedback[key]?.label}`}
            labelClass={"training-input-label"}
            placeHolder={`Enter ${formData?.generalFeedback[key]?.label}`}
            errorColor={"red-color"}
            errorMesg={formHandler?.isErrorShow[index]?.message}
            isErrorShow={
              formHandler?.isErrorShow[index]?.error &&
              !Object?.hasOwn(formHandler?.formValues, key)
            }
            onChange={(val) =>
              OnChangeValue(
                val?.target?.value,
                key,
                formHandler?.isErrorShow[index]?.error
              )
            }
          />
        ))}
        {formData?.feedbackQuestions?.map((item, parentIndex) => (
          <>
            <Text label={item?.text} fontWeight={"500"} fontSize={"14px"} />
            {item?.category == "single" && (
              <>
                {item?.options?.map((key, index) => (
                  <RadioButton
                    fontWeight={"300"}
                    key={index}
                    checked={checkBoxValue.find(
                      (obj) =>
                        obj.index === parentIndex + 1 &&
                        obj.type === "single" &&
                        obj.currentIndex === index + 1
                    )}
                    label={key}
                    onChange={(val) =>
                      OnCheckBoxValue(key, index + 1, parentIndex + 1, "single")
                    }
                  />
                ))}
                {checkBoxValue.find((item, index) =>
                  item?.index !== 6 && item?.index === parentIndex + 1
                    ? item?.key === "No"
                    : item?.index === parentIndex + 1 && item?.key === "Yes"
                ) && <InputWithTitle />}
              </>
            )}

            {item?.subTitles?.map((key, subIndex) => (
              <>
                <Text marginBottom={"0px"} label={key} marginTop={"10px"} />
                {item?.category == "double" &&
                  item?.options?.map((key, index) => (
                    <RadioButton
                      fontWeight={"300"}
                      checked={checkBoxValue.find(
                        (obj) =>
                          obj?.currentIndex === index + 1 &&
                          obj?.index === subIndex + 1 &&
                          obj?.type === "double"
                      )}
                      label={key}
                      onChange={(val) => {
                        OnCheckBoxValue(key, index + 1, subIndex + 1, "double");
                      }}
                    />
                  ))}
                {checkBoxValue.find(
                  (item) =>
                    item?.index === subIndex + 1 &&
                    (item?.key == "Poor" || item?.key == "Below Expectations")
                ) && <InputWithTitle />}
              </>
            ))}
          </>
        ))}
      </div>
      <SimpleButton
        onSubmit={onSubmitbutton}
        label={"Submit"}
        buttonClass={"feedback-submit-button"}
      />
    </div>
  );
};

export default FeedbackForm;
