// import React from "react";
// import Spinner from "react-bootstrap/Spinner";
// export const SimpleButton = ({ label, icons, buttonClass, isSpinner,onSubmit }) => {
//   return (
//     <div>
//       <button className={buttonClass} onClick={onSubmit}>
//         {isSpinner ? <Spinner animation="grow" variant="light" /> :  label }
//       </button>
//     </div>
//   );
// };

import React from "react";
import Spinner from "react-bootstrap/Spinner";

export const SimpleButton = ({
  label = "Button", // Default label
  icons,
  buttonClass = "", // Default to no class
  isSpinner = false,
  onClick,
  type = "button", // Default button type
}) => {
  return (
    <button
      className={buttonClass}
      onClick={onClick}
      type={type}
      aria-busy={isSpinner} // Accessibility
      disabled={isSpinner} // Disable button while spinner is active
    >
      {isSpinner ? <Spinner animation="grow" variant="light" /> : label}
    </button>
  );
};
