
import React, { useState } from "react";
import "pages/Home/index.css";
import Header from "components/Header/header";
import { Helmet } from "react-helmet";
import weblogo from "assets/images/MS-BLUE-LTD-logo-1.png";

const AppealsPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Appeals Policy</title>
            </Helmet>
            <Header />

            <div className="container">
                <div className="row">
                </div>
                <div className="col-md-12 mb-contact-resp">
                    {/* <div className="px-5"> */}
                        {/* <h2 style={{ color: "#3a47bc" }} className="mb-text">
                        Contact
                        </h2> */}
                        <h1 style={{ color: "#88d5d3", fontSize:"38px" }}>Appeals Policy </h1>
                        {/* <div className="AppealsPolicy-table">
                            <table>
                                <tr>
                                    
                                    <td class="title-cell" colspan="2">APPEALS POLICY</td>
                                </tr>
                                <tr>
                                    <td>Document</td>
                                    <td>MSBCD/QP/002</td>
                                </tr>
                                <tr>
                                    <td>Version</td>
                                    <td>2</td>
                                </tr>
                                <tr>
                                    <td>Created by</td>
                                    <td>Ali Waqar</td>
                                </tr>
                                <tr>
                                    <td>Authorised</td>
                                    <td>Monika Swatek</td>
                                </tr>
                                <tr>
                                    <td>Issue date</td>
                                    <td>22/07/2023</td>
                                </tr>
                                <tr>
                                    <td>Reviewed</td>
                                    <td>01/03/2024</td>
                                </tr>
                                <tr>
                                    <td>Due date</td>
                                    <td>01/03/2026</td>
                                </tr>
                            </table>

                        </div> */}
                        
                        <div style={{ paddingTop: "20px" }}>
                            <h4>1. Purpose and Scope</h4>
                            <p>MS Blue Ltd is dedicated to providing transparent and fair processes for individuals
                                who wish to appeal decisions made by the organization that directly affect them. This
                                Appeals Policy outlines the procedures for lodging and handling appeals and aims to
                                ensure that all appeals are addressed promptly, impartially, and with due process.
                            </p>
                            <p>This policy applies to all clients, employees, contractors, and stakeholders
                                associated with MS Blue Ltd.
                            </p>
                            <h4>2. Definition of an Appeal  </h4>
                            <p>An appeal is defined as a request for a formal review of a decision made by MS Blue
                                Ltd that directly affects an individual or group. Appeals may relate to training
                                assessments, certification outcomes, training center policies, or any other decisions
                                that fall within our scope of control.</p>
                            <h4>3. Appeals Handling Process</h4>
                            <h5>3.1 Initiating an Appeal</h5>
                            <p>Individuals who wish to lodge an appeal must submit a written appeal to MS Blue Ltd
                                within 7 business days of receiving the decision they are appealing.</p>
                            <p>The appeal must clearly state the grounds for the appeal, providing specific reasons
                                and supporting evidence.</p>
                            <h5>3.2 Appeals Review Panel</h5>
                            <p>MS Blue Ltd will establish an Appeals Review Panel (ARP) consisting of impartial
                                and qualified personnel not directly involved in the original decision.</p>
                            <p>The ARP will be responsible for reviewing the appeal and making a fair and unbiased
                                decision.</p>
                            <h5>3.3 Acknowledgment and Record-Keeping</h5>
                            <p>Upon receipt of an appeal, MS Blue Ltd will acknowledge the appeal in writing within
                                14 business days.</p>
                            <p>The appeal and all related documentation will be recorded in a secure and
                                confidential manner.</p>
                            <h5>3.4 Appeals Review Process</h5>
                            <p>The ARP will conduct a thorough and independent review of the appeal, including all
                                relevant documents and evidence provided by the appellant and any other relevant
                                parties.</p>
                            <p>The ARP may seek additional information or clarification from the appellant or other
                                parties involved in the original decision.</p>
                            <p>The appeal review process will be completed within 14 business days, unless the
                                circumstances require an extension. In such cases, the appellant will be informed of
                                the revised timeframe.</p>
                            <h5>3.5 Appeals Decision</h5>
                            <p>The ARP will reach a decision based on the merits of the appeal and the available
                                evidence</p>
                            <p>The decision of the ARP will be communicated to the appellant in writing, providing
                                the rationale behind the decision.</p>
                            <h5>3.6 Right to Appeal Outcome</h5>
                            <p>If the appellant is not satisfied with the outcome of the appeal, they may have the
                                right to seek further escalation, as outlined in the Appeals Outcome Communication.
                            </p>
                            <h5>3.7 Appeals to Awarding organisation</h5>
                            <p>Learner can appeal any decision made by Awarding Organisation e.g.: to CILT(UK),
                                appealing a reasonable adjustments decision, a malpractice decision, enquiries
                                about results etc.</p>
                            <h4>4. Review and Continuous Improvement
                            </h4>
                            <p>MS Blue Ltd is committed to regularly reviewing the effectiveness of the Appeals
                                Policy and making improvements as necessary. Feedback from appeals and their
                                resolutions will be used to enhance our decision-making processes and overall
                                operations.</p>
                            <h4>5. Communication
                            </h4>
                            <p>This Appeals Policy will be made available to all clients and stakeholders through our
                                official website, intranet, and training materials.</p>
                            <p>By implementing this Appeals Policy, MS Blue Ltd aims to uphold fairness, integrity,
                                and accountability in all aspects of our operations, thereby fostering trust and
                                confidence among our clients and stakeholders.
                            </p>
                            {/* <p><strong>MONIKA SWATEK</strong></p> */}
                            <p><strong>General Manager</strong></p>
                            <p><strong>MS BLUE LTD</strong></p>
                        </div>

                    </div>
                {/* </div> */}
            </div>


        </>
    );
};

export default AppealsPolicy;
